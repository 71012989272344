<template>
    <div v-if="userLogged()" class="panel-user-logged">
        <div class="container-fluid align-middle">
            <img :src="userPhotoProfile" alt="User" id="user__img" width="32" height="32" class="rounded-circle me-2" /> <span id="user__name">{{ this.userFullname }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DataTopLogged',
    data() {
        return {
        userPhotoProfile: null,
        userFullname: null,
        }
    },

    methods: {
        userLogged() {
        const logged = !(localStorage.getItem('authUser') === null);

        if (logged) {
            const user = JSON.parse(localStorage.getItem('authUser'));  
            this.userPhotoProfile = user.photo;
            this.userFullname = user.first_name + ' ' + user.last_name;
        }

        return logged;
        }
    }
}    
</script>

<style scoped>
.panel-user-logged {
    background: rgba(255, 255, 255, 0.75);
    padding-top: 6px;    
    padding-bottom: 6px;  
    font-weight: 500;  
  }
</style>