<template>
  <div id="initial" class="d-flex justify-content-center align-items-center">
      <div class="box row">
        <div
          class="col-md-6 box-logo d-flex flex-column justify-content-between align-items-center"
        >
          <div class="">
            <a href="#">
              <img
                src="https://catch.inf.br/assets/img/logomarca.webp"
                alt="Logo"
                width="220"
              />
            </a>
          </div>
          <div class="text-white">
            <div class="fs-18 fw-bold text-center mb-3">Cadastro Catch</div>
            <div class="text-center">
              Preencha os dados corretamente para realizar seu cadastro.
            </div>
          </div>
          <div class="text-white text-center fs-14">
            Já tem conta?<br>            
            <router-link to="/login" class="btn btn-account-create fs-14 fw-bold mt-3">Fazer login</router-link>            
          </div>
        </div>
        <div class="col-md-6 box-fields">
          <!--<div class="alert-danger lista-de-erros py-2 px-4 mb-3" v-if="errors.length">
            <ul>
              <li v-for="(error, index) in errors" v-bind:key="index">{{ error }}</li>
            </ul>
          </div>-->


          <Form id="account-form" class="login-form" method="POST" @submit="validateBeforeSubmit" action="#">
            <div class="resultado_login"></div> 

            <div v-show="showPanel1" class="mb-3">            
              <Field name="first_name" type="text" class="form-control" autofocus v-model="first_name" rules="required|minMaxLength:3,20" placeholder="Ex. Jane" maxlength="20" />
              <ErrorMessage class="error_validation" name="first_name" />
            </div>

            <div v-show="showPanel1" class="mb-3">              
              <Field name="last_name" type="text" class="form-control" v-model="last_name" rules="required|minMaxLength:3,20" placeholder="Ex. Souza" maxlength="20" />
              <ErrorMessage class="error_validation" name="last_name" />
            </div>

            <div v-show="showPanel1" class="mb-3">
              <Field name="email" type="email" class="form-control" v-model="email" rules="required|email" placeholder="Ex. usuário@catch.inf.br" maxlength="120" />
              <ErrorMessage class="error_validation" name="email" />
            </div>

            <div v-show="showPanel1" class="mb-3">
              <Field id="password__input" name="password" type="password" class="form-control" v-model="password" rules="required|minMaxLength:6,20" placeholder="Senha" maxlength="20" />
              <ErrorMessage class="error_validation" name="password" />
            </div>

            <div v-show="showPanel1" class="mb-3">                
                <Field id="birthdate__input" name="birthdate" type="date" min="1900-01-01" class="form-control" v-model="birthdate" rules="required|ofLegalAge" placeholder="Nascimento" maxlength="10" />
                <ErrorMessage class="error_validation" name="birthdate" />
            </div>

            <div v-show="showPanel1" class="mb-3">                
                <Field name="phone_number" type="text" class="form-control" placeholder="Ex. (81)99666-0101" maxlength="14" v-model="phone_number" v-mask="'(##)#####-####'" rules="required|length:14" />
                <ErrorMessage class="error_validation" name="phone_number" />
            </div> 

            <div v-show="showPanel2" class="mb-3">
                <div class="text-center fs-18 fw-bold mb-3">Envie sua selfie</div>

                <p class="fs-16">
                    Para concluir seu cadastro, por favor, nos envie uma selfie sua.
                </p>
            </div>

            <div v-show="showPanel2" class="mb-3">
                <label class="picture rounded-circle" for="picture__input" tabIndex="0">
                    <span class="picture__image"></span>
                </label>
                <Field name="photo">
                  <input type="file" id="picture__input" @change="handleChange" @blur="handleBlur" />
                </Field>
            </div> 

            <div id="loader" class="mb-3">
                <i class="fa fa-spinner fa-spin"></i> Aguarde...
            </div>
            
            <div v-if="showPanel2">
              <Field type="checkbox" name="terms_and_conditions" class="mb-3 cursor-pointer" value="true" v-model="terms_and_conditions" rules="accept" /> Aceito os <a href="#" data-bs-toggle="modal" data-bs-target="#terms-modal">termos e condições</a>.
              <ErrorMessage class="error_validation" name="terms_and_conditions" />
            </div>

            <button v-if="showPanel1" type="submit" class="btn btn-continue btn-go w-100 fs-14 fw-bold">Continuar</button>            
            <button v-if="showPanel2" type="submit" class="btn btn-done btn-go w-100 fs-14 fw-bold">Concluir</button>
          </Form>
        </div>
      </div>
    </div>

    <div class="modal fade" id="terms-modal" tabindex="-1" aria-labelledby="terms-modal-label" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="terms-modal-label">Termos e condições</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p class="fw-bold">Tipos de dados coletados</p>
              <p>Entre os tipos de dados pessoais que este aplicativo recolhe, por si só ou por meio de terceiros, são: e-mail, nome, sobrenome, senha, telefone, sexo, endereço e imagem.</p>
              <p>Outros dados pessoais recolhidos podem ser descritos em outras seções desta política de privacidade ou pelo texto explicativo específico apresentado no contexto da coleta de dados.</p>
              <p>Os dados pessoais podem ser livremente fornecidos pelo usuário, ou coletados automaticamente quando se utiliza este aplicativo.</p>
              <p>Qualquer uso de cookies – ou de outras ferramentas de rastreamento – pelo aplicativo ou pelos proprietários dos serviços terceirizados utilizados por ele, salvo indicação em contrário, servem para identificar os usuários e lembrar as suas preferências, com o único propósito de fornecer os serviços requeridos por eles.</p>
              <p>O não fornecimento de determinados dados pessoais pode tornar impossível para este aplicativo prestar os seus serviços.</p>
              <p>O usuário assume a responsabilidade pelos dados pessoais de terceiros publicados ou compartilhados por meio deste serviço (este aplicativo) e confirma que tem o consentimento da parte terceira para fornecer dados para o proprietário.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn bn-white text-dark" data-bs-dismiss="modal">Fechar</button>              
            </div>
          </div>
        </div>
  </div>
</template>

<script setup>
import { defineRule } from 'vee-validate';
import { Form, Field, ErrorMessage } from 'vee-validate';
import axios from 'axios';

defineRule('ofLegalAge', (value) => {    
    if (!value || !value.length) {
        return true;
    }

    if (!isOfLegalAge(value)) {
        return `* Autorizado apenas para maiores de idade`;
    }
    return true;
});

defineRule('accept', value => {
    if (!value || !value.length) {
        return '* Aceite obrigatório';
    }
    return true;
});
</script>

<script>
import { useToast } from 'vue-toastification'
const toast = useToast()

function renderPictureInput() {  
  const inputFile = document.querySelector("#picture__input");
  const pictureImage = document.querySelector(".picture__image");
  const pictureImageTxt = "Selecionar";
  pictureImage.innerHTML = pictureImageTxt;

  inputFile.addEventListener("change", function (e) {
      const inputTarget = e.target;
      const file = inputTarget.files[0];

      if (file) {
          const reader = new FileReader();

          reader.addEventListener("load", function (e) {
          const readerTarget = e.target;

          const img = document.createElement("img");
          img.src = readerTarget.result;
          img.width = 400;
          img.classList.add("picture__img");

          pictureImage.innerHTML = "";
          pictureImage.appendChild(img);
          });

          reader.readAsDataURL(file);
      } else {
          pictureImage.innerHTML = pictureImageTxt;
      }
  });  
}

function isOfLegalAge(birthday){
	var optimizedBirthday = birthday.replace(/-/g, "/");
	var myBirthday = new Date(optimizedBirthday);
	var currentDate = new Date().toJSON().slice(0,10)+' 01:00:00';
	var myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));

	return (myAge >= 18);
}

function getDataMax() {
  const date = new Date();
  const year = date.getFullYear() - 18;
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
}

export default {
  name: 'AccountCreate',
  props: {
    msg: String
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      showPanel1: true,
      showPanel2: false,      
      first_name: null,      
      last_name: null,      
      email: null,    
      password: null,
      phone_number: null,
      birthdate: null,
      /*first_name: 'Danilo',      
      last_name: 'Ângelo',      
      email: 'danilloangelo@hotmail.com',    
      password: '123456',
      phone_number: '81996374494',
      birthdate: '1992-02-05',*/
      terms_and_conditions: false,
      photo: null,
    }
  },
  mounted() {
    const inputBirthdate = document.querySelector("#birthdate__input");
    inputBirthdate.max = getDataMax();  

    if (this.$route.query.first_name != undefined) {
      this.first_name = this.$route.query.first_name;
    }

    if (this.$route.query.last_name != undefined) {
      this.last_name = this.$route.query.last_name;
    }

    if (this.$route.query.email != undefined) {
      this.email = this.$route.query.email;
    }

    if (this.$route.query.birthdate != undefined && this.$route.query.birthdate != '') {      
      this.birthdate = this.convertDateEN(this.$route.query.birthdate);
      document.querySelector("#password__input").focus();
    }
    
    /*axios
      .get('https://api.coindesk.com/v1/bpi/currentprice.json')
      .then(response => (console.log(response.data)))*/
  },
  methods: {
    handleChange(e) {
      // Check if file is selected
      if (e.target.files && e.target.files[0]) {
        // Get uploaded file
        const file = e.target.files[0],
          // Get file size
          fileSize = Math.round((file.size / 1024 / 1024) * 100) / 100,
          // Get file extension
          fileExtention = file.name.split(".").pop(),
          // Get file name
          fileName = file.name.split(".").shift(),
          // Check if file is an image
          isImage = ["jpg", "jpeg", "png", "gif"].includes(fileExtention);
        // Print to console
        console.log(fileSize, fileExtention, fileName, isImage);
      }
    },

    validateBeforeSubmit(values) {            
      if (this.showPanel1) {
        this.showPanel1 = false;        
        this.showPanel2 = true; 
        
        renderPictureInput();
        return false;
      } else {
        const data = JSON.stringify(values);
        const self = this;

        axios.post('https://sandboxapi.catch.inf.br/v1/users', data, {
          headers: {
            'content-type': 'application/json'
          },
          auth: {
            username: 'CatchAPI',
            password: '@C4tch@c$1aPi#t!App#*'
          }          
        })
        .then(function() {          
          toast.success("Cadastrado com sucesso!", { timeout: 1000 });           

          axios.get('https://sandboxapi.catch.inf.br/v1/users/'+self.email, {
            headers: {
              'content-type': 'application/json'
            },
            auth: {
              username: 'CatchAPI',
              password: '@C4tch@c$1aPi#t!App#*'
            }          
          })
          .then(function(response) {        
            if (response.data.exists === true) {
              console.error(response);
              localStorage.setItem('authUser', JSON.stringify(response.data.data));    
            }        
          });

          window.setTimeout(function() {
            self.$router.push('/boas-vindas');                                
          }, 500);
        })
        .catch(function (error) {                                               
          toast.error(error.response.data.message, { timeout: 4000 });
          console.error(error.response.data.message);          
          self.showPanel2 = false;           
          self.showPanel1 = true;           
        });
        //console.log(res.status); // 200
        //err.message; // "Request failed with status code 401"
        //err.response.status; // 401 "Unauthorized"
        //document.querySelector("#account-form").submit();
      }
    },

    convertDateEN(date) {
        let date_br = date.substring(0, 10);
        let date_en = date_br.split('/').reverse().join('-');
        let hour = '';

        if (date.length > 10) {
            hour = ' '+date.substring(11, 19);
        }
        
        return date_en + hour;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#initial {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url('../assets/img/backgroud-login.webp');
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

#initial .box {
  width: 824px;
  min-height: 400px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

#initial .box-logo {
  background: rgba(20, 38, 48, .75); /* #142630*/
  padding: 70px 50px;
}

#initial .box-logo p {
  text-indent:2em;
  margin-left:0px;
  margin-right:0px;
  font-weight:400;
  font-size: 14px;
}

#initial .box-logo .btn {
  font-size: 14px;  
  width: 200px;
}

#initial .box-logo .btn-account-create {
  background-color: #F00505;
  border-bottom: 3px solid #c22522;
  color: #FFF;
}

#initial .box-logo .btn-account-create:hover {
  opacity: 0.75;
}

#initial .box-fields {
  background: rgba(255, 255, 255, .75); /* #fff*/
  padding: 70px 50px;
}

#initial .box-fields p {
  font-size: 14px;
}

#initial .box-fields .btn-go {
  font-size: 14px;
  background-color: #F00505;
  border-bottom: 3px solid #c22522;
  color: #FFF;
  padding: 8px 10px;

}

#initial .box-fields .btn-go:hover {
  opacity: 0.75;
}

#initial .box-fields a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}

#initial .box-fields ul {
  list-style: none;
  padding-left: 0px;
}

#initial .box-fields ul li {
  padding-bottom: 8px;
}

#initial .box-fields ul a {
  background-color: #FFF;
  font-size: 14px;
  width: 100%;
  text-align: left;
  font-weight: 500;
}

#initial .box-fields ul a:hover {
  opacity: .85;
}

#initial .box-fields input {
  color: #FFF;
  background: rgba(20, 38, 48, .75); /* #142630*/
  font-size: 14px;
  padding: 10px;
}

#initial .box-fields input::placeholder {
  color: #A2A2A2;
}

#picture__input {
  display: none;
}

.picture {  
  aspect-ratio: 16/16;
  background: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;  
  cursor: pointer;
  font-family: sans-serif;
  transition: color 300ms ease-in-out, background 300ms ease-in-out;
  outline: none;
  overflow: hidden;
}

.picture:hover {
  color: #777;
  background: #FFF;
}

.picture:active {
  border-color: turquoise;
  color: turquoise;
  background: #eee;
}

.picture:focus {
  color: #777;
  background: #ccc;
}

.picture__img {
  max-width: 100%;
}

.lista-de-erros ul {
  margin-bottom: 0px !important;
}

.lista-de-erros ul li {
  font-size: 12px;
  font-weight: 500;
  list-style: disc;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.error_validation {
  font-size: 11px;
  background: #F00;
  color: #FFF;
  position: absolute;
  margin-top: -1px;
  margin-left: 1px;
  padding: 0px 4px 0px 4px;  
}
</style>
