<template>
  <div id="main" class="d-flex justify-content-center align-items-center">
    <div class="box">
      <div class="box-center w-100">       
        <div class="text-start mb-3">
          <h3>Configurações</h3>
          <hr/>
        </div>

        <Form id="setting-form" method="POST" @submit="onSubmit" action="#">
          <div class="mb-3 checkbox-catch">
            <Field v-slot="{ field }" name="matching_activated" type="checkbox" :value="true" v-model="matching_activated">
                <input type="checkbox" id="chk_matching_activated" name="matching_activated" v-bind="field" :value="true" />
                <label for="chk_matching_activated">
                  Matching ativado
              </label>
            </Field>
          </div>

          <div class="mb-3">
            <Field v-slot="{ value }" name="id_area" class="form-control" v-model="id_area"  as="select" @change="loadSkills($event.target.value)">
              <option value="">Selecione uma área de atuação...</option>
              <option v-for="area in areas" :key="area.id" :value="area.id" :selected="value && value == area.id">{{ area.area }}</option>              
            </Field>
            <ErrorMessage class="error_validation" name="id_area" />
          </div>

          <div class="mb-3">
            <Field name="skills" as="select" multiple class="form-control" v-model="id_skills" rules="required">
              <option value="" disabled>Selecione suas habilidades...</option>
              <option v-for="skill in skills" :key="skill.id" :value="skill.id" :selected="id_skills.some(e => e == skill.id)">{{ skill.skill }}</option>
            </Field> 
            <ErrorMessage class="error_validation" name="id_skills" />
          </div>   
          
          <div class="mb-3">
            <input type="range" id="range" name="radius_range" v-model="radius_range" min="1" max="20">
          </div>
          
          <div class="text-end">
            <button type="button" id="btn-done-back" class="btn btn-back fs-14 fw-bold" @click="goBack()">Voltar</button>
            <button type="submit" id="btn-done-settings" class="btn btn-done fs-14 fw-bold">Concluir</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification'
const toast = useToast()
import { Form, Field, ErrorMessage } from 'vee-validate';

export default {
  name: 'TheSettings',
  components: {
    Form, 
    Field, 
    ErrorMessage    
  },
  created() {        
    if (localStorage.getItem('authUser')) {
      this.id_area = JSON.parse(localStorage.getItem('authUser')).id_area;
      this.id_skills = JSON.parse(localStorage.getItem('authUser')).skills.map(({id}) => id);
      this.matching_activated = JSON.parse(localStorage.getItem('authUser')).matching_activated == '1';
      this.radius_range = 4; // JSON.parse(localStorage.getItem('authUser')).radius_range;

      this.loadAreas();
      this.loadSkills(this.id_area);
    }
  },
  data() {
    return {
      id_area : null,
      areas : [],
      id_skills : [],
      skills : [],
      matching_activated: false,
      radius_range: 0,
    }
  },
  methods: {
    goBack() {
      this.emitter.emit("show-menu-settings", this.showMenuSettings);
    },

    loadAreas() {
      let self = this;

      axios.get('https://sandboxapi.catch.inf.br/v1/areas', {
        headers: {
          'content-type': 'application/json'
        },
        auth: {
          username: 'CatchAPI',
          password: '@C4tch@c$1aPi#t!App#*'
        }          
      })
      .then(function(response) {
        self.areas = response.data.list;
      })
      .catch(function (error) {
        toast.error('Erro ao carregar as àreas de atuação, tente novamente mais tarde!', { timeout: 4000 });
        console.error(error.response.data.message);
      });
    },

    loadSkills(id_area) {
      let self = this;

      if (id_area == null) {
        self.skill = [];
      } else {        
        axios.get('https://sandboxapi.catch.inf.br/v1/skills?id_area='+id_area, {
          headers: {
            'content-type': 'application/json'
          },
          auth: {
            username: 'CatchAPI',
            password: '@C4tch@c$1aPi#t!App#*'
          }          
        })
        .then(function(response) {
          self.skills = response.data.list;
        })
        .catch(function (error) {
          toast.error('Erro ao carregar as àreas de atuação, tente novamente mais tarde!', { timeout: 4000 });
          console.error(error.response.data.message);
        });
      }
    },

    onSubmit(values) {
      let self = this;
      const id_user = JSON.parse(localStorage.getItem('authUser')).id;
      const id_area = values.id_area;
      const radius_range = 4;// values.radius_range;
      const matching_activated = (values.matching_activated == true ? 1 : 0);
      const skills = values.skills;      

      const data = JSON.stringify({
        matching_activated: matching_activated,
        id_area: id_area,
        skills: skills,
        radius_range: radius_range,
      });

      axios.put('https://sandboxapi.catch.inf.br/v1/users/' + id_user + '/profile', data, {
        headers: {
          'content-type': 'application/json'
        },
        auth: {
          username: 'CatchAPI',
          password: '@C4tch@c$1aPi#t!App#*'
        }          
      })
      .then(function(response) {
        toast.success("Operação realizada com sucesso!", { timeout: 2000 });
        localStorage.setItem('authUser', JSON.stringify(response.data.data));
        self.goBack();
      })
      .catch(function (error) {
        toast.error('Não foi possível atualizar as configurações!', { timeout: 4000 });
        console.error(error.response.data.message);
      });
    }
  }
}
</script>

<style scoped>
#main .box {
  width: 824px;
  min-height: 400px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  color: #333;
}

#main .box-center {
  background: rgba(255, 255, 255, .85); 
  padding: 40px 40px;
}

#main .box-center p {
  text-indent: 20px;
  font-size: 16px;
  font-weight: 400;
}

#main .btn-done {
  background-color: #F00505;
  border-bottom: 3px solid #c22522;
  color: #FFF;
  width: 140px;
}

#main .btn-done:hover {
  opacity: 0.75;
}

#main .btn-back {
  color: #333;
  width: 140px;
}

#main select[name="skills"] {
  cursor: pointer;
  min-height: 160px;
}

#main .checkbox-catch {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
#main .checkbox-catch input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
#main .checkbox-catch label {
  position: relative;
  padding-left: 2.2em;
  display: inline-block;
}
#main .checkbox-catch label::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 1.5em;
  width: 1.5em;
  background: #FFF;
  margin-right: 0.5em;
  border: 1px solid #999;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
#main .checkbox-catch label::after {
  content: "\2713";
  position: absolute;
  top: 50%;
  left: 0.75em;
  transform: translate(-50%, -50%);
  font-size: 1em;
  color: #FFF;
  opacity: 0;
  transition: all 0.2s ease;
}
#main .checkbox-catch label:focus::before, #main  .checkbox-catch label:hover::before {
  background: #DDD;
}
#main .checkbox-catch input:checked + label::before {
  background: #3A3938;
  border-color: #3A3938;
}
#main .checkbox-catch input:checked + label::after {
  opacity: 1;
}
</style>