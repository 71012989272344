<template>
  <div id="main" class="d-flex justify-content-center align-items-center">
    <div class="box">
      <div class="container">
        <div class="box-center">       
          <div class="text-start mb-3">
              <div class="float-end">
                <img :src="userPhotoProfile" alt="User" width="64" height="64" class="rounded-circle border shadow me-2" /> 
              </div>
              <div class="top">
                <h3>Perfil</h3>
                <router-link :to="unavaliable" v-on:click="unavaliable">Visualizar e editar perfil</router-link>
              </div>
            <hr/>
          </div>
          
          <div v-show="this.userProfileActivation == 'N'" class="text-start bg-secondary text-white shadow p-3">
            <button class="text-white" style="border: none; background: none;">
              <img src="../assets/img/cadeado-32.png" alt="Cadeado" width="16" height="16" style="margin-top: -4px" class="me-2" />
              <router-link to="/ativacao?url_redirect=perfil" class="text-white">Para usar os recursos da sua conta do Catch, você precisa ativar sua conta. É rápido!</router-link>
            </button>
          </div>

          <div v-show="this.userProfileActivation == 'P'" class="text-start bg-pendeting text-white shadow p-3">
            <span class="text-white text-start" style="border: none; background: none;">
              <img src="../assets/img/ampulheta-32.png" alt="Cadeado" width="16" height="16" style="margin-top: -4px" class="me-2" />
              Seu documento está em análise. Em breve você poderá utilizar as funcionalidades do Catch!
            </span>
          </div>
          
          <ul class="list-group list-group-flush mt-3">
            <li class="list-group-item bg-transparent list-group-item-action">
              <router-link :to="unavaliable" v-on:click="unavaliable">
                <div class="d-flex justify-content-between">
                  <span>Notificações</span>
                  <span><img src="../assets/img/notification-24.png" alt="Notificações" width="22" height="22" style="margin-top: -4px" class="me-2" /></span>
                </div>
              </router-link>
            </li>
            <li class="list-group-item bg-transparent list-group-item-action">
              <router-link :to="unavaliable" v-on:click="unavaliable">
                <div class="d-flex justify-content-between">
                  <span>Convidar amigos</span>
                  <span><img src="../assets/img/gift-24.png" alt="Convidar" width="22" height="22" style="margin-top: -4px" class="me-2" /></span>
                </div>
              </router-link>
            </li>
            <li class="list-group-item bg-transparent list-group-item-action">
              <a href="javascript:void(0)" @click="drawMenuSettings">
                <div class="d-flex justify-content-between">
                  <span>Configurações</span>
                  <span><img src="../assets/img/settings-24.png" alt="Configurações" width="22" height="22" style="margin-top: -4px" class="me-2" /></span>
                </div>
              </a>
            </li>
            <li class="list-group-item bg-transparent list-group-item-action">
              <router-link to="/perfil/tipo">
                <div class="d-flex justify-content-between">
                  <span>Tipo de Perfil</span>
                  <span><img src="../assets/img/help-24.png" alt="Ajuda" width="22" height="22" style="margin-top: -4px" class="me-2" /></span>
                </div>
              </router-link>
            </li>
            <li class="list-group-item bg-transparent list-group-item-action">
              <router-link :to="unavaliable" v-on:click="unavaliable">
                <div class="d-flex justify-content-between">
                  <span>Ajuda</span>
                  <span><img src="../assets/img/help-24.png" alt="Ajuda" width="22" height="22" style="margin-top: -4px" class="me-2" /></span>
                </div>
              </router-link>
            </li>
            <li class="list-group-item bg-transparent list-group-item-action">
              <router-link :to="unavaliable" v-on:click="unavaliable">
                <div class="d-flex justify-content-between">
                  <span>Enviar seu Feedback</span>
                  <span><img src="../assets/img/message-24.png" alt="Feedback" width="22" height="22" style="margin-top: -4px" class="me-2" /></span>
                </div>
              </router-link>
            </li>          
          </ul>
        </div>
      </div>      
    </div>

    <TheSettings v-if="showMenuSettings"></TheSettings>
  </div>
</template>

<script>
import TheSettings from './TheSettings'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
  name: 'TheProfile',
  data() {
      return {
        userPhotoProfile: null,
        userFullname: null,
        showMenuSettings: false,
        userProfileActivation: 'N',
      }
  },
  components: {
    TheSettings
  },
  created() {
      const logged = !(localStorage.getItem('authUser') === null);
      if (logged) {
          const user = JSON.parse(localStorage.getItem('authUser'));  
          this.userPhotoProfile = user.photo;
          this.userFullname = user.first_name + ' ' + user.last_name;
          this.userProfileActivation = user.profile_activated;                  
      }
  },
  mounted() { 
    this.emitter.on("show-menu-settings", showMenuSettings => {
      this.showMenuSettings = showMenuSettings;      
    });
  },
  methods: {
    drawMenuSettings() {
      this.showMenuSettings = true;      
    },
    unavaliable() {      
      toast.warning('Opção indisponível no momento!', { timeout: 5000 }); 
      return; 
    }
  }
}
</script>

<style scoped>
#main {
  min-height: 90vh;
}

#main .box {
  width: 824px;
  min-height: 400px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  color: #333;
}

#main .box a {
  text-decoration: none;
}

#main .box-center {
  background: rgba(255, 255, 255, .85); 
  padding: 40px 40px;
}

#main .box-center p {
  text-indent: 20px;
  font-size: 16px;
  font-weight: 400;
}

#main .top a {
  color: #F10606;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}

.list-group a {
  text-decoration: none;
  color: #333;
}

.list-group .list-group-item:hover {
  background-color: #D3D3D3 !important;
}

.bg-pendeting {
  background: rgba(241, 6, 6, 0.50);
}
</style>