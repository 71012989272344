<template>
  <div id="main">
    <DataTopLogged />
    <div class="container py-4">
      <div class="content w-100">        
          <div class="text-start mb-2">
            <h3>Anúncios</h3>
            <hr/>
          </div>    

          <div class="d-flex btns-options-list">                        
            <button @click="listAdAvaliable()" id="btn-ad-avaliabled" class="btn fs-14 fw-bold my-2 actived">Disponíveis</button>
            <button @click="listAdMatching()"  id="btn-ad-matching" class="btn fs-14 fw-bold my-2">Matching</button>
            <button @click="listAdDone()"      id="btn-ad-done" class="btn fs-14 fw-bold my-2">Finalizados</button>
          </div>

          <AdAvaliablesProfessional :show_component="this.show_ad_avaliable"></AdAvaliablesProfessional>
          <AdMatchingProfessional :show_component="this.show_ad_matching"></AdMatchingProfessional>
          <AdDoneProfessional :show_component="this.show_ad_done"></AdDoneProfessional>                    
      </div>
    </div>
  </div>
</template>

<script>
import DataTopLogged from '@/components/DataTopLogged.vue';
import AdAvaliablesProfessional from '@/components/AdAvaliablesProfessional.vue';
import AdMatchingProfessional from '@/components/AdMatchingProfessional.vue';
import AdDoneProfessional from '@/components/AdDoneProfessional.vue';

export default {
  name: 'TheAdverts',
  components: {
    DataTopLogged,
    AdAvaliablesProfessional,
    AdMatchingProfessional,
    AdDoneProfessional
  },
  data() {
    return {
      show_ad_avaliable: true,
      show_ad_matching: false,
      show_ad_done: false,
      btn_ad_avaliable: null,
      btn_ad_matching: null,
      btn_ad_done: null,
    }
  },
  mounted() {
    this.btn_ad_avaliable = document.getElementById('btn-ad-avaliabled');
    this.btn_ad_matching = document.getElementById('btn-ad-matching');
    this.btn_ad_done = document.getElementById('btn-ad-done');
  },
  methods: {
    listAdAvaliable() {
      this.show_ad_avaliable = true;
      this.show_ad_matching = false;
      this.show_ad_done = false;     
      
      this.btn_ad_avaliable.classList.add("actived");
      this.btn_ad_matching.classList.remove("actived");
      this.btn_ad_done.classList.remove("actived");
    },

    listAdMatching() {
      this.show_ad_avaliable = false;
      this.show_ad_matching = true;
      this.show_ad_done = false;

      this.btn_ad_avaliable.classList.remove("actived");
      this.btn_ad_matching.classList.add("actived");
      this.btn_ad_done.classList.remove("actived");      
    },

    listAdDone() {
      this.show_ad_avaliable = false;
      this.show_ad_matching = false;
      this.show_ad_done = true;

      this.btn_ad_avaliable.classList.remove("actived");
      this.btn_ad_matching.classList.remove("actived");
      this.btn_ad_done.classList.add("actived");      
    }
  }
}
</script>

<style scoped>
#main {
  min-height: 90vh;
}

#main .content {
  min-height: 600px;
  color: #333;
  background: rgba(255, 255, 255, .85); 
  padding: 40px 40px;  
}

#main .btn-done {
  background-color: #F00505;
  border-bottom: 3px solid #c22522;
  color: #FFF;
  width: 140px;
}

#main .btn-done:hover {
  opacity: 0.75;
}

#main .btn-back {
  color: #333;
  width: 140px;
}

.btns-options-list button.actived {
  background-color: #142630 !important;
  color: #FFF;
}

.btns-options-list button:hover {
  background-color: #F3F3F3;
}
</style>