<template>
    <div v-show="show_component">
        <p class="mt-3">Anúncios filtrados por área e habilidades configuradas no seu perfil.</p>

        <div class="alert bg-white" v-show="this.advertisements.length == 0">
            Nenhum anúncio encontrada para os filtros selecionados
        </div>    

        <div class="mx-0 row">    
            <div v-for="(advertisement, index) in advertisements" :key="advertisement.id" :class="(index & 1) ? 'col-lg-6 col-xl-4 mb-2 pe-4' : 'col-lg-6 col-xl-4 mb-2 pe-4'">
                <div class="row bg-white py-3 px-1 mb-2">
                    <div class="col-lg-12">
                        <img :src="advertisement.photo" alt="" class="img-fluid"/>
                    </div>
                    <div class="col-lg-12">
                        <h3>{{ advertisement.title }}</h3>
                        <span class="badge bg-warning">{{ advertisement.area }}</span> <span class="badge bg-info">{{ advertisement.skill }}</span><br>
                        <div class="my-3">
                            Data: {{ convertDateBR(advertisement.created_at) }}<br>
                            Propriedade: {{ getDescriptionPropertyType(advertisement.property_type) }}
                        </div>                        
                        <div class="text-end">      
                            <span class="float-start fs-12 fw-bold bg-catch-red text-white px-2 shadow opacity-75">
                                {{ (advertisement.candidacy == "1") ? 'Você já se candidatou' : '' }}                            
                            </span>

                            <button class="btn-more-info" @click="showMoreInfo(advertisement.id)" >+ Mais Informação</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modalMoreInfo" tabindex="-1" aria-labelledby="modalMoreInfoLabel" aria-hidden="true">
            <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title" id="modalMoreInfoLabel"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                                
                </div>
                <div class="modal-footer">
                    
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification'
const toast = useToast()
import { Modal } from 'bootstrap'

export default {
  name: 'AdAvaliablesProfessional',
  props: {
    show_component: Boolean
  },
  data() {
    return {
        user_logged: {},
        advertisements: []
    }
  },
  mounted() {
    this.advertsementsList();
  },
  methods: {
    advertsementsList() {
        let self = this;
        this.user_logged = JSON.parse(localStorage.getItem('authUser'));  
        this.userPhotoProfile = this.user_logged.photo;
        this.userFullname = this.user_logged.first_name + ' ' + this.user_logged.last_name;

        const id_area = this.user_logged.id_area;
        const id_skill = this.user_logged.skills.map(({id}) => id);      
        const id_candidate = this.user_logged.id;
        const uri = 'https://sandboxapi.catch.inf.br/v1/advertisements'+
                        '?id_area='+id_area+
                        '&id_skill='+id_skill+
                        '&status=A'+
                        '&id_candidate='+id_candidate;

        axios.get(uri, {
            headers: {
                'content-type': 'application/json'
            },
            auth: {
                username: 'CatchAPI',
                password: '@C4tch@c$1aPi#t!App#*'
            }          
        })
        .then(function(response) {
            self.advertisements = response.data.list;            
        })
        .catch(function (error) {            
            toast.error(error.response.data.message, { timeout: 4000 });
            console.error(error.response.data.message);
      });
    },

    getDescriptionPropertyType(property_type) {
        switch (property_type) {
            case 'A':
                return 'Apartamento';
            case 'H':
                return 'Casa';
            case 'B':
                return 'Prédio';
            default:
                return 'Outro';
        }
    },

    convertDateBR(date) {
        let date_en = date.substring(0, 10);
        let date_br = date_en.split('-').reverse().join('/');
        let hour = '';

        if (date.length > 10) {
            hour = ' '+date.substring(11, 19);
        }
        
        return date_br + hour;
    },

    showMoreInfo(id_advertisement) {
        let self = this;
        const modalMoreInfo = new Modal(document.getElementById('modalMoreInfo'));
        const modalTitle   = document.getElementById('modalMoreInfo').getElementsByClassName('modal-title')[0];  
        const modalContent = document.getElementById('modalMoreInfo').getElementsByClassName('modal-body')[0];  
        const modalFooter  = document.getElementById('modalMoreInfo').getElementsByClassName('modal-footer')[0];  
        const id_candidate = this.user_logged.id;      
        
        const uri = 'https://sandboxapi.catch.inf.br/v1/advertisements/'+id_advertisement+                        
                        '?id_candidate='+id_candidate;

        axios.get(uri, {
            headers: {
                'content-type': 'application/json'
            },
            auth: {
                username: 'CatchAPI',
                password: '@C4tch@c$1aPi#t!App#*'
            }          
        })
        .then(function(response) {
            const advertisement = response.data.data;
            modalTitle.innerText   = advertisement.title.substring(0, 40);
            modalContent.innerHTML = `<div class="mb-3">
                                            <span class="fw-bold">Data:</span> ${self.convertDateBR(advertisement.created_at)}<br>
                                            <span class="fw-bold">Área:</span> ${advertisement.area}<br>
                                            <span class="fw-bold">Habilidade Solicitada:</span> ${advertisement.skill}<br>
                                            <span class="fw-bold">Cidade:</span> ${advertisement.city + ' - ' + advertisement.state}                                     
                                        </div>
                                        <div class="mb-3">
                                            <p>${advertisement.description}</p>
                                        </div>`;

            if (advertisement.candidacy == "1") {
                modalFooter.innerHTML = `<div class="text-end button-candidacy">
                                        </div>`;
            } else {
                modalFooter.innerHTML = `<div class="mb-1 w-100">
                                            <textarea id="field_candidate_message" class="form-control" name="candidate_message" rows="2" placeholder="Digite uma mensagem para o criador do anúncio..."></textarea>
                                        </div>
                                        <div class="text-end button-candidacy">
                                        </div>`;
            }
            
            const divButtonCandidacy = modalFooter.getElementsByClassName('button-candidacy')[0];             

            if (advertisement.candidacy == "1") {     
                const button = document.createElement('span');            
                button.setAttribute('class', 'fw-bold fs-14')
                button.textContent = 'Você já se candidatou a essa vaga';                
                divButtonCandidacy.appendChild(button);
            }

            modalMoreInfo.show();

            const textareaCandidateMessage = document.getElementById('field_candidate_message');            
            if (textareaCandidateMessage) {
                const button = document.createElement('button');            
                button.setAttribute('class', 'btn btn-danger btn-me-candidatar')
                button.textContent = 'Me candidatar a vaga';
                button.addEventListener('click', () => self.ApplyForOpportunity(id_advertisement, textareaCandidateMessage.value, modalMoreInfo), false);
                divButtonCandidacy.appendChild(button);  

                window.setTimeout(function() {
                    textareaCandidateMessage.focus();                                      
                }, 500);
            }
        })
        .catch(function (error) {            
            toast.error(error.response.data.message, { timeout: 4000 });
            console.error(error.response.data.message);
        });        
    },

    ApplyForOpportunity(id_advertisement, candidate_message, modalMoreInfo) {
        const id_candidate = this.user_logged.id;      
        const data = JSON.stringify({id_advertisement: id_advertisement, id_user_candidate: id_candidate, candidate_message: candidate_message})
        axios.post('https://sandboxapi.catch.inf.br/v1/advertisements/apply-for', data, {
            headers: {
                'content-type': 'application/json'
            },
            auth: {
                username: 'CatchAPI',
                password: '@C4tch@c$1aPi#t!App#*'
            }          
        })
        .then(function(response) {
            modalMoreInfo.hide();            
            toast.success(response.data.message, { timeout: 5000 });
            console.log(response);            
        })
        .catch(function (error) {
            toast.error(error.response.data.message, { timeout: 4000 });
            console.error(error.response.data.message);
        });
    }
  }
}
</script>

<style>
.btn-more-info {
    background-color: #142630;
    border: none;
    color: #FFF;
    padding: 3px 8px;
    font-size: 14px;
}

.btn-me-candidatar {
    background-color: #F10606;
}
</style>