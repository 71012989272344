<template>
  <div id="main">
    <DataTopLogged />  
    <div class="d-flex justify-content-center align-items-center h-100 text-white">
      Main
    </div>
  </div>
</template>

<script>
import DataTopLogged from '@/components/DataTopLogged.vue';

export default {
  name: 'TheMain',
  components: {
    DataTopLogged    
  },
  props: {
    msg: String
  }
}
</script>

<style>
#main {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url('../assets/img/backgroud-login.webp');
  background-size: cover;
  width: 100%;  
  position: absolute; 
}

#main .box {
  width: 824px;
  min-height: 400px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

#main .box-logo {
  background: rgba(20, 38, 48, .75); /* #142630*/
  padding: 70px 50px;
}

#main .box-logo p {
  text-indent:2em;
  margin-left:0px;
  margin-right:0px;
  font-weight:400;
  font-size: 14px;
}

#main .box-logo .btn {
  font-size: 14px;  
  width: 200px;
}

#main .box-logo .btn-account-create {
  background-color: #F00505;
  border-bottom: 3px solid #c22522;
  color: #FFF;
}

#main .box-logo .btn-account-create:hover {
  opacity: 0.75;
}

#main .box-fields {
  background: rgba(255, 255, 255, .75); /* #fff*/
  padding: 70px 50px;
}

#main .box-fields p {
  font-size: 14px;
}

#main .box-fields .btn-go {
  font-size: 14px;
  background-color: #F00505;
  border-bottom: 3px solid #c22522;
  color: #FFF;
  padding: 8px 10px;

}

#main .box-fields .btn-go:hover {
  opacity: 0.75;
}

#main .box-fields a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}

#main .box-fields ul {
  list-style: none;
  padding-left: 0px;
}

#main .box-fields ul li {
  padding-bottom: 8px;
}

#main .box-fields ul button,
#main .box-fields ul a {
  background-color: #FFF;
  font-size: 14px;
  width: 100%;
  text-align: left;
  font-weight: 500;
}

#main .box-fields ul a:hover {
  opacity: .85;
}

#main .box-fields input {
  color: #FFF;
  background: rgba(20, 38, 48, .75); /* #142630*/
  font-size: 14px;
  padding: 10px;
}

#main .box-fields input::placeholder {
  color: #A2A2A2;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.bg-catch-blue {
  background: #142630;
}

.bg-catch-red {
  background: #F10606;
}
</style>
