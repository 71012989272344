<template>
  <div id="main" class="d-flex justify-content-center align-items-center">
    <div class="box">
      <div class="box-center">       
        <div class="text-center mb-3">
          <a href="#">
            <img
              src="https://catch.inf.br/assets/img/logomarca.webp"
              alt="Logo"
              width="220"
            />
          </a>
        </div>

        <div class="text-start">
          <p>
            Selecione o seu perfil de uso no aplicativo, lembrando que o tipo pode ser alterado, sempre que deseja, desde que respeite o período mínino de permanênia no perfil.
          </p>
        </div>

        <div class="row">
          <div class="col buttons p-3 my-3">
            <button :class="profile_type == 'P' ? 'actived' : '' " @click="setProfileType('P')">
              <img src="../assets/img/professional-48.png" alt="Profissional" width="48" height="48" /><br>
              Sou um profissional habilidoso procurando oportunidade
            </button>
          </div>
          <div class="col buttons p-3 my-3">
            <button :class="profile_type == 'C' ? 'actived' : '' " @click="setProfileType('C')">
              <img src="../assets/img/cliente-48.png" alt="Cliente" width="48" height="48" /><br>
              Sou um cliente precisando de alguém com habilidade
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
  name: 'AccountProfileType',
  data() {
    return {
      profile_type: 'P'
    }
  },
  created() {
      const logged = !(localStorage.getItem('authUser') === null);
      if (logged) {
          const user = JSON.parse(localStorage.getItem('authUser'));  
          this.profile_type = user.profile_type;          
      }
  },
  methods: {
    setProfileType(type) {
      const data = JSON.stringify({
        'profile_type': type
      });

      const id_user = JSON.parse(localStorage.getItem('authUser')).id;

      if (id_user == undefined) {        
        toast.error("Não estamos conseguindo acessar os dados do usuário logado!", { timeout: 4000 });
      } else {      
        let self = this;

        axios.put('https://sandboxapi.catch.inf.br/v1/users/'+id_user+'/profile/type', data, {
          headers: {
            'content-type': 'application/json'
          },
          auth: {
            username: 'CatchAPI',
            password: '@C4tch@c$1aPi#t!App#*'
          }          
        })
        .then(function(response) {
          toast.success("Perfil atualizado com sucesso!", { timeout: 1000 });        
          console.log(response);
          localStorage.setItem('authUser', JSON.stringify(response.data.data));          
          self.$router.push('/perfil');
        })
        .catch(function (error) {
          toast.error(error.response.data.message, { timeout: 6000 });
          console.error(error.response.data.message);
        });
      }
    }
  }
}
</script>

<style scoped>
#main {
  min-height: 90vh;
}

#main .box {
  width: 824px;
  min-height: 400px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  color: #FFF;
}

#main .box-center {
  background: rgba(20, 38, 48, .75);
  padding: 40px 40px;
}

#main .box-center p {
  text-indent: 20px;
  font-size: 16px;
  font-weight: 400;
}

#main .box-center .buttons {
}

#main .box-center .buttons button {
  background: rgba(255, 255, 255, .75); 
  max-width: 300px;
  padding: 25px 15px;
  display: block;
  margin: 0 auto;
  color: #333;
  text-decoration: none;
  text-align: center;
  border: none;
}

#main .box-center .buttons button img {
  margin-bottom: 20px;
}

#main .box-center .buttons button:hover  {
  background: rgba(255, 255, 255, 0.90); 
}

#main .box-center .buttons button.actived  {
  background: rgba(255, 255, 255, 1); 
}
</style>