<template>
  <div v-if="notIsLoginPage" class="nav-top">    
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container-fluid">        
        <a class="navbar-brand" href="/" title="Logo"><img id="logo" src="https://catch.inf.br/assets/img/logomarca.webp" width="175" height="48" class="d-inline-block align-top" alt="" loading="lazy"></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <div class="navbar-nav me-auto mb-2 mb-lg-0">
            WebApp
          </div>
          <div class="d-flex">                        
            <router-link to="/anuncios" class="btn fs-14 fw-bold my-2"><img src="./assets/img/advert-16.png" alt="Configurações" width="16" height="16" class="me-1"/> Anúncios</router-link>
            <router-link to="/perfil" class="btn fs-14 fw-bold my-2"><img src="./assets/img/settings-16.png" alt="Configurações" width="16" height="16" class="me-1"/> Perfil</router-link>
            <button @click="logout()" class="btn fs-14 fw-bold my-2"><img src="./assets/img/logout-16.png" alt="Sair" width="16" height="16" class="me-1"/> Sair</button>
          </div>
        </div>
      </div>
    </nav>
  </div>

  <nav v-if="false">
    <router-link to="/cadastro">Cadastro</router-link>            
    <router-link to="/login">Login</router-link>
  </nav>
  <router-view/>  
</template>

<script>
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
  data() {
    return {
      userPhotoProfile: null,
      userFullname: null,
    }
  },

  computed: {
    notIsLoginPage() {
      return this.$route.name !== "login" && this.$route.name !== "register";
    },    
  },

  methods: {
    logout() {
      localStorage.removeItem('authUser');
      toast.error("Você não está mais logado!", { timeout: 2000, icon: false });      
      this.$router.push({name: "login"});      
    }
  }
}
</script>

<style scoped>
.nav-top {
  background: #142630;
  color: #FFF !important;
}

.nav-top a, .nav-top button {
  color: #FFF;
}
</style>