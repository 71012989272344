import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Toast from "vue-toastification";
import VueMask from '@devindex/vue-mask';
import * as VeeValidate from 'vee-validate';
import ValidationProvider  from 'vee-validate';
import { defineRule } from 'vee-validate';
import vue3GoogleLogin from 'vue3-google-login';
import "vue-toastification/dist/index.css";
import mitt from 'mitt';
import 'bootstrap/dist/js/bootstrap';

const emitter = mitt();

const app = createApp(App);
app.use(router);
app.use(VueMask);
app.use(vue3GoogleLogin, {clientId: '63499707637-8a21njjl7h82cgdplvt2p64mtjg9egjo.apps.googleusercontent.com'});
app.component('ValidationProvider', ValidationProvider);

app.use(VeeValidate, {
    inject: true,
    fieldsBagName: '$veeFields',
    errorBagName: '$veeErrors'
});  

app.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true
});

app.config.globalProperties.emitter = emitter;

app.mount('#app');


defineRule('required', value => {
    if (!value || !value.length) {
        return '* Campo obrigatório';
    }
    return true;
});

defineRule('email', value => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }
    // Check if email
    if (!/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}/.test(value)) {
        return '* E-mail inválido';
    }
    return true;
});

defineRule('minLength', (value, [limit]) => {
    // The field is empty so it should pass
    if (!value || !value.length) {
        return true;
    }
    if (value.length < limit) {
        return `* O campo deve ter pelo menos ${limit} caracteres`;
    }
    return true;
});

defineRule('maxLength', (value, [limit]) => {
    // The field is empty so it should pass
    if (!value || !value.length) {
        return true;
    }
    if (value.length < limit) {
        return `* O campo deve ter até ${limit} caracteres`;
    }
    return true;
});

defineRule('minMaxLength', (value, [min, max]) => {
    // The field is empty so it should pass
    if (!value || !value.length) {
        return true;
    }    

    const numericValue = value.length;
    if (numericValue < min || numericValue > max) {
        return `* O campo deve ter entre ${min} e ${max} caracteres`;    
    }
    
    return true;
});

defineRule('minMax', (value, [min, max]) => {
    // The field is empty so it should pass
    if (!value || !value.length) {
        return true;
    }    

    const numericValue = Number(value);
    if (numericValue < min || numericValue > max) {
        return `* O campo deve ter entre ${min} e ${max} caracteres`;    
    }
    
    return true;
});

defineRule('length', (value, [length]) => {
    // The field is empty so it should pass

    if (value.length != length) {
        return `* O campo deve ter ${length} caracteres`;
    }
    return true;
});