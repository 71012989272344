<script setup>
/*import { googleAuthCodeLogin } from "vue3-google-login"
const logInWithGoogle = () => {
  googleAuthCodeLogin().then((response) => {
    console.log("Handle the response1", response)
    console.log("Handle the response2", response.user)
    console.log("Handle the response3", response.credential)
  })
}*/
</script>

<template>
  <div id="initial" class="d-flex justify-content-center align-items-center">
    <div class="box row">
      <div
        class="col-md-6 box-logo d-flex flex-column justify-content-between align-items-center"
      >
        <div class="">
          <a href="#">
            <img
              src="https://catch.inf.br/assets/img/logomarca.webp"
              alt="Logo"
              width="220"
            />
          </a>
        </div>
        <div class="text-white">
          <div class="fs-18 fw-bold text-center mb-3">Sejam bem-vindos ao Catch Web</div>
          <p>
            O app que conecta diversas oportunidades ao seu perfil de acordo
            com suas habilidades.
          </p>
        </div>
        <div class="text-white text-center fs-14">
          Ainda não tem cadastro?<br>
          <router-link to="/cadastro" class="btn btn-account-create fs-14 fw-bold mt-3">Criar conta</router-link>
        </div>
      </div>

      <div class="col-md-6 box-fields">
        <p class="mb-3 fw-bold">Conecte-se utilizando uma das opções abaixo:</p>
        <Form id="login-form" class="login-form" method="POST" @submit="validateBeforeSubmit" action="#">
          <div class="resultado_login"></div>

          <div class="mb-3">
            <Field name="email" type="email" class="form-control" autofocus v-model="first_name" rules="required|email|minMaxLength:3,120" placeholder="Ex. usuário@catch.inf.br" maxlength="120" />
            <ErrorMessage class="error_validation" name="email" />
          </div>  

          <div class="mb-3">
            <Field name="password" type="password" class="form-control" v-model="password" rules="required|minMaxLength:6,20" placeholder="Senha" maxlength="20" />
              <ErrorMessage class="error_validation" name="password" />
          </div>  

          <div id="loader" class="mb-3">
              <i class="fa fa-spinner fa-spin"></i> Aguarde...
          </div>
          
          <button type="submit" class="btn btn-go w-100 fs-14 fw-bold">Entrar</button>

          <div class="text-end pt-1 mb-3">
              <a href="./esqueci-minha-senha" class="fs-14">Esqueceu sua senha?</a>
          </div>

          <ul>            
            <li style="padding-bottom: 2px;">
              <GoogleLogin class="btn-google" :callback="logInWithGoogle"/>              
            </li>
            <li>
              <button type="button" class="btn" @click="logInWithFacebook">
                <img src="../assets/img/facebook-48.png" alt="Facebook" width="24" height="24" class="me-2"> Conectar com o Facebook
              </button>
            </li>
            <!--<li>
              <a class="btn" id="btn__tiktok" href="#">
                <img src="../assets/img/tiktok-48.png" alt="Tiktok" width="24" height="24" class="me-2"> Conectar com o Tiktok
              </a>
            </li>-->
          </ul>
        </Form>
      </div>
    </div>
  </div>
</template>


<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import axios from 'axios';
import { useToast } from 'vue-toastification'
const toast = useToast()
import { decodeCredential } from 'vue3-google-login'

//import AccountCreate from './components/AccountCreate';
export default {
  name: 'TheLogin',
  components: {
    Form,
    Field,
    ErrorMessage,    
  },
  props: {
    msg: String
  },
  data() {
    return {
      email: '',    
      password: ''
    }
  },
  async mounted() {
    const btnTikTok = document.querySelector("#btn__tiktok");
    const csrfState = Math.random().toString(36).substring(2);

    let url = 'https://www.tiktok.com/auth/authorize/';

    url += '?client_key=awqq2ucoh3hmhb9p';
    url += '&scope=user.info.basic,video.list';
    url += '&response_type=code';
    url += '&redirect_uri=https://app.catch.inf.br';
    url += '&state=' + csrfState;

    btnTikTok.href = url;
    //localStorage.removeItem('authUser');

    await this.loadFacebookSDK(document, "script", "facebook-jssdk"); 
    await this.initFacebook(); 
  },
  methods: { 
    showToast() {
      alert('teste');
      toast.info("Info toast");
      alert('teste2');
    },
    validateBeforeSubmit(values) {            
      const data = JSON.stringify(values);      
      let self = this;

      axios.post('https://sandboxapi.catch.inf.br/v1/login', data, {
        headers: {
          'content-type': 'application/json'
        },
        auth: {
          username: 'CatchAPI',
          password: '@C4tch@c$1aPi#t!App#*'
        }          
      })
      .then(function(response) {
        toast.success("Login realizado com sucesso!", { timeout: 1000 });
        localStorage.setItem('authUser', JSON.stringify(response.data.data));
        self.$router.push('/anuncios');
      })
      .catch(function (error) {
        toast.error(error.response.data.message, { timeout: 4000 });
        console.error(error.response.data.message);
      });
    },

    logInWithGoogle(response) {  
      const self = this;
      const userData = decodeCredential(response.credential);
      const email = userData.email;
      const given_name = userData.given_name;
      const family_name = userData.family_name;

      axios.get('https://sandboxapi.catch.inf.br/v1/users/'+email, {
        headers: {
          'content-type': 'application/json'
        },
        auth: {
          username: 'CatchAPI',
          password: '@C4tch@c$1aPi#t!App#*'
        }          
      })
      .then(function(response) {        
        if (response.data.exists === true) {
          toast.success("Login realizado com sucesso!", { timeout: 1000 });
          localStorage.setItem('authUser', JSON.stringify(response.data.data));
          self.$router.push('/anuncios');
        } else {
          self.$router.push('/cadastro?first_name='+given_name+'&last_name='+family_name+'&email='+email);
        }
      })
      .catch(function () {
        self.$router.push('/cadastro?first_name='+given_name+'&last_name='+family_name+'&email='+email);
      });

      // "picture": "https://lh3.googleusercontent.com/a/AGNmyxYiH_-5fkLNCn3RYlQsqmvjgu6OGkHOpWf38Oh34Q=s96-c",            
    },

    async logInWithFacebook() { 
      const self = this;

      window.FB.login(function(response) {
        if (response.authResponse) {           
          window.FB.api('/me?fields=id,first_name,last_name,email,birthday,picture.type(large)', function(response) {
            const email = response.email;
            const first_name = response.first_name;
            const last_name = response.last_name;
            const birthday = response.birthday;
            
            axios.get('https://sandboxapi.catch.inf.br/v1/users/'+email, {
              headers: {
                'content-type': 'application/json'
              },
              auth: {
                username: 'CatchAPI',
                password: '@C4tch@c$1aPi#t!App#*'
              }          
            })
            .then(function(response) {        
              if (response.data.exists === true) {
                toast.success("Login realizado com sucesso!", { timeout: 1000 });
                localStorage.setItem('authUser', JSON.stringify(response.data.data));
                self.$router.push('/anuncios');
              } else {
                self.$router.push('/cadastro?first_name='+first_name+'&last_name='+last_name+'&email='+email+'&birthdate='+birthday);
              }
            })
            .catch(function () {
              self.$router.push('/cadastro?first_name='+first_name+'&last_name='+last_name+'&email='+email+'&birthdate='+birthday);
            });            
          });
        } else {
          toast.warning("O usuário cancelou o login ou não autorizou totalmente.", { timeout: 4000 });          
        }
      }, {scope: 'public_profile,email,user_birthday'});
      return false;
    },

    async initFacebook() {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: "518102549858959",
          cookie: true, // Isso é importante, não está ativado por padrão
          version: "v13.0"
        });
      };
    },

    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }
  }
}
</script>

<style scoped>
#initial {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url('../assets/img/backgroud-login.webp');
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

#initial .box {
  width: 824px;
  min-height: 400px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

#initial .box-logo {
  background: rgba(20, 38, 48, .75); /* #142630*/
  padding: 70px 50px;
}

#initial .box-logo p {
  text-indent:2em;
  margin-left:0px;
  margin-right:0px;
  font-weight:400;
  font-size: 14px;
}

#initial .box-logo .btn {
  font-size: 14px;  
  width: 200px;
}

#initial .box-logo .btn-account-create {
  background-color: #F00505;
  border-bottom: 3px solid #c22522;
  color: #FFF;
}

#initial .box-logo .btn-account-create:hover {
  opacity: 0.75;
}

#initial .box-fields {
  background: rgba(255, 255, 255, .75); /* #fff*/
  padding: 70px 50px;
}

#initial .box-fields p {
  font-size: 14px;
}

#initial .box-fields .btn-go {
  font-size: 14px;
  background-color: #F00505;
  border-bottom: 3px solid #c22522;
  color: #FFF;
  padding: 8px 10px;

}

#initial .box-fields .btn-go:hover {
  opacity: 0.75;
}

#initial .box-fields a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}

#initial .box-fields ul {
  list-style: none;
  padding-left: 0px;
}

#initial .box-fields ul li {
  padding-bottom: 8px;
}
#initial .box-fields ul button,
#initial .box-fields ul a {
  background-color: #FFF;
  font-size: 14px;
  width: 100%;
  text-align: left;
  font-weight: 500;
}

#initial .box-fields ul a:hover {
  opacity: .85;
}

#initial .box-fields input {
  color: #FFF;
  background: rgba(20, 38, 48, .75); /* #142630*/
  font-size: 14px;
  padding: 10px;
}

#initial .box-fields input::placeholder {
  color: #A2A2A2;
}

.btn-google {
  width: 100%;
  font-size: 14px;    
  font-weight: 500;
}
</style>