import { createRouter, createWebHashHistory } from 'vue-router'
import TheLogin from '../components/TheLogin.vue'
import TheMain from '../components/TheMain.vue'
import AccountCreate from '../components/AccountCreate.vue'
import TheWelcome from '../components/TheWelcome.vue'
import TheProfile from '../components/TheProfile.vue'
import TheAdverts from '../components/TheAdverts.vue'
import AccountActivation from '../components/AccountActivation.vue'
import AccountProfileType from '../components/AccountProfileType.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: TheMain
  },
  {
    path: '/cadastro',
    name: 'register',
    component: AccountCreate
  },
  {
    path: '/boas-vindas',
    name: 'welcome',
    component: TheWelcome
  },  
  {
    path: '/perfil',
    name: 'profile',
    component: TheProfile
  },  
  {
    path: '/anuncios',
    name: 'adverts',
    component: TheAdverts
  },
  {
    path: '/perfil/tipo',
    name: 'account-profile-type',
    component: AccountProfileType
  },  
  {
    path: '/ativacao',
    name: 'account-activation',
    component: AccountActivation
  },  
  {
    path: '/login',
    name: 'login',
    component: TheLogin
  }  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (
    to.name !== "login" &&
    to.name !== "register" &&
    !localStorage.getItem("authUser")
  ) {
    next({ name: "login" });
  } else if (to.name == "" || to.name == "home"){
    next({ name: "adverts" });  
  } else {
    next();
  }
});

export default router