<template>
  <div id="main">
    <DataTopLogged />

    <div class="container py-5">      
      <div class="content w-100">
        <div class="text-start mb-2">
          <h3>Parabéns!
            Agora você faz parte do Catch! 🎉🥳</h3>
          <hr/>
        </div>
        <div class="mb-3">
          Agora você só precisa ativar sua conta para começar a utilizar todos os recursos do Catch.

          <div class="buttons row mt-5">
            <div class="col-sm-6 mb-3">              
              <button type="button" class="btn btn-activate fs-14 fw-bold" @click="goToAccountActivation">Ativar Conta</button>                          
            </div>
            <div class="col-sm-6 mb-3">
              <button type="button" class="btn btn-later fs-14 fw-bold" @click="goToProfileType">Deixar para mais tarde</button>             
            </div>
          </div>
        </div>
      </div>    
    </div>
  </div>
</template>

<script>
import DataTopLogged from '@/components/DataTopLogged.vue';

export default {
  name: 'TheWelcome',
  data() {
      return {
      userPhotoProfile: null,
      userFullname: null,
      showMenuSettings: false,
      }
  },
  components: {
    DataTopLogged
  },
  created() {
      const logged = !(localStorage.getItem('authUser') === null);
      if (logged) {
          const user = JSON.parse(localStorage.getItem('authUser'));  
          this.userPhotoProfile = user.photo;
          this.userFullname = user.first_name + ' ' + user.last_name;
      }
  },
  mounted() { 
    this.emitter.on("show-menu-settings", showMenuSettings => {
      this.showMenuSettings = showMenuSettings;      
    });
  },
  methods: {
    drawMenuSettings() {
      this.showMenuSettings = true;      
    },

    goToAccountActivation() {
      this.$router.push('/ativacao');
    },

    goToProfileType() {
      this.$router.push('/perfil/tipo');
    }
  }
}
</script>

<style scoped>
#main {
  min-height: 100vh;
}

#main .container {
  max-width: 768px;
}

#main .content {  
  color: #333;
  background: rgba(255, 255, 255, .85); 
  padding: 40px 40px;  
}

#main .buttons {
  max-width: 400px;
  margin:  auto;
}

#main .buttons .btn-activate {
  border: 3px solid #F00505;
  color: #F00505;
  width: 180px;
  padding-top: 30px;
  padding-bottom: 30px;  
}

#main .buttons .btn-later {
  background-color: #F00505;
  border-bottom: 3px solid #c22522;
  color: #FFF;
  width: 180px;
  padding-top: 30px;
  padding-bottom: 30px;
}

#main .btn-done:hover {
  opacity: 0.75;
}

#main .btn-back {
  color: #333;
  width: 140px;
}

.btns-options-list button.actived {
  background-color: #142630 !important;
  color: #FFF;
}

.btns-options-list button:hover {
  background-color: #F3F3F3;
}
</style>