<template>
  <div id="main">
    <DataTopLogged />
    <div class="container py-4">
      <div class="content w-100">        
          <div class="text-start mb-2">
            <h3>Ativação de sua conta</h3>
            <hr/>
          </div>   
          
          <p>Para validarmos os dados informados no cadastro, envie uma foto da frente e outra do verso de seu RG.</p>

          <Form id="account-form" class="mt-3" method="POST" @submit="validateBeforeSubmit" action="#" enctype="multipart/form-data">
            <div class="row">                        
              <div class="col-md-6 mb-3">
                Frente
                <label class="picture mt-1" for="picture__input" tabIndex="0">
                    <span class="picture__image"></span>
                </label>
                <Field name="photo">
                  <input type="file" id="picture__input" @change="handleChange($event)" @blur="handleBlur" accept="image/*" capture />
                </Field>         
              </div>
              <div class="col-md-6 mb-3">
                Verso
                <label class="picture mt-1" for="picture__input2" tabIndex="0">
                  <span class="picture__image2"></span>
                </label>
                <Field name="photo">
                  <input type="file" id="picture__input2" @change="handleChange2($event)" @blur="handleBlur"  accept="image/*" capture />
                </Field>
              </div>
              <div class="col-12 text-end">
                <button type="submit" class="btn btn-send btn-go fs-14 fw-bold px-3">Enviar</button>
              </div>
            </div>
          </Form>
      </div>
    </div>
  </div>
</template>

<script>
import DataTopLogged from '@/components/DataTopLogged.vue';
import { Form, Field } from 'vee-validate';
import { useToast } from 'vue-toastification'
import axios from 'axios';
const toast = useToast()

function renderPictureInput() {  
  const inputFile = document.querySelector("#picture__input");
  const pictureImage = document.querySelector(".picture__image");
  const pictureImageTxt = "Selecionar";
  pictureImage.innerHTML = pictureImageTxt;

  inputFile.addEventListener("change", function (e) {
      const inputTarget = e.target;
      const file = inputTarget.files[0];

      if (file) {
          const reader = new FileReader();

          reader.addEventListener("load", function (e) {
          const readerTarget = e.target;

          const img = document.createElement("img");
          img.src = readerTarget.result;
          img.width = 400;
          img.classList.add("picture__img");

          pictureImage.innerHTML = "";
          pictureImage.appendChild(img);
          });

          reader.readAsDataURL(file);
      } else {
          pictureImage.innerHTML = pictureImageTxt;
      }
  });  
}

function renderPictureInput2() {  
  const inputFile = document.querySelector("#picture__input2");
  const pictureImage = document.querySelector(".picture__image2");
  const pictureImageTxt = "Selecionar";
  pictureImage.innerHTML = pictureImageTxt;

  inputFile.addEventListener("change", function (e) {
      const inputTarget = e.target;
      const file = inputTarget.files[0];

      if (file) {
          const reader = new FileReader();

          reader.addEventListener("load", function (e) {
          const readerTarget = e.target;

          const img = document.createElement("img");
          img.src = readerTarget.result;
          img.width = 400;
          img.classList.add("picture__img");

          pictureImage.innerHTML = "";
          pictureImage.appendChild(img);
          });

          reader.readAsDataURL(file);
      } else {
          pictureImage.innerHTML = pictureImageTxt;
      }
  });  
}

export default {
  name: 'AccountActivation',
  components: {
    DataTopLogged,
    Form,
    Field,
  },
  data() {
    return {
      id_user: 0,
      profile_activated: false,
      url_redirect: '/perfil/tipo',
      rg_front: null,
      rg_back: null,
    }
  },
  created() {        
    if (localStorage.getItem('authUser')) {
      this.id_user = JSON.parse(localStorage.getItem('authUser')).id;
      this.profile_activated = JSON.parse(localStorage.getItem('authUser')).profile_activated;      
    }
  },
  mounted() {
    renderPictureInput();
    renderPictureInput2();

    if (this.$route.query.url_redirect != undefined) {
      this.url_redirect = this.$route.query.url_redirect;
    }
  },
  
  methods: {
    handleChange(e) {
      // Check if file is selected
      if (e.target.files && e.target.files[0]) {
        // Get uploaded file
        const file = e.target.files[0],
          // Get file size
          fileSize = Math.round((file.size / 1024 / 1024) * 100) / 100,
          // Get file extension
          fileExtention = file.name.split(".").pop(),
          // Get file name
          fileName = file.name.split(".").shift(),
          // Check if file is an image
          isImage = ["jpg", "jpeg", "png", "gif"].includes(fileExtention);
        // Print to console
        console.log(fileSize, fileExtention, fileName, isImage);
        this.rg_front = e.target.files[0];
      }
    },

    handleChange2(e) {
      // Check if file is selected      
      if (e.target.files && e.target.files[0]) {        
        // Get uploaded file
        const file = e.target.files[0],
          // Get file size
          fileSize = Math.round((file.size / 1024 / 1024) * 100) / 100,
          // Get file extension
          fileExtention = file.name.split(".").pop(),
          // Get file name
          fileName = file.name.split(".").shift(),
          // Check if file is an image
          isImage = ["jpg", "jpeg", "png", "gif"].includes(fileExtention);
        // Print to console
        console.log(fileSize, fileExtention, fileName, isImage);
        this.rg_back = e.target.files[0];
      }
    },

    validateBeforeSubmit() {      
      const self = this;

      let formData = new FormData();
      formData.append('photo_rg_front', self.rg_front);
      formData.append('photo_rg_back',  self.rg_back);      
            
      axios.post('https://sandboxapi.catch.inf.br/v1/users/'+self.id_user+'/profile/active', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        auth: {
          username: 'CatchAPI',
          password: '@C4tch@c$1aPi#t!App#*'
        }          
      })
      .then(function(response) {
        toast.success(response.data.message, { timeout: 5000 });
        const auth_user = JSON.parse(localStorage.getItem('authUser'));
        auth_user.profile_activated = 'P';
        localStorage.setItem('authUser', JSON.stringify(auth_user));
        
        window.setTimeout(function() {
          self.$router.push(self.url_redirect);                                
        }, 500);
      })
      .catch(function (error) {                                               
        toast.error(error.response.data.message, { timeout: 4000 });
        console.error(error.response);          
      });
    },
  }
}
</script>

<style scoped>
#main {
  min-height: 90vh;
}

#main .container {
  max-width: 768px;
}

#main .content {  
  color: #333;
  background: rgba(255, 255, 255, .85); 
  padding: 40px 40px;  
}

#picture__input, #picture__input2{
  display: none;
}

.picture {  
  aspect-ratio: 16/10;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;  
  cursor: pointer;
  font-family: sans-serif;
  transition: color 300ms ease-in-out, background 300ms ease-in-out;
  outline: none;
  overflow: hidden;
}

.picture:hover {
  color: #777;
  opacity: 0.85;
}

.picture:active {
  border-color: turquoise;
  color: turquoise;
  background: #eee;
}

.picture:focus {
  color: #777;
  background: #ccc;
}

.picture__img {
  max-width: 100%;
}

.lista-de-erros ul {
  margin-bottom: 0px !important;
}

.lista-de-erros ul li {
  font-size: 12px;
  font-weight: 500;
  list-style: disc;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.error_validation {
  font-size: 11px;
  background: #F00;
  color: #FFF;
  position: absolute;
  margin-top: -1px;
  margin-left: 1px;
  padding: 0px 4px 0px 4px;  
}

.btn-go {
  font-size: 14px;
  background-color: #F00505;
  border-bottom: 3px solid #c22522;
  color: #FFF;
  padding: 8px 10px;

}

.btn-go:hover {
  opacity: 0.75;
}
</style>